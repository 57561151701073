.input-type-date-container {
    display: flex;
    border-radius: 10px;
    margin-top: 5px;
}

.input-type-date-text {
    width: 118px;
    padding: 10px 2px 10px 10px;
    border: none;
    border-radius: 10px 0 0 10px;
}

.input-type-date-text[type="text"] {
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.input-type-date-button {
    cursor: pointer;
    border-radius: 0 10px 10px 0;
    color: white;
    border: none;
}

.input-type-date-button-dark {
    background-color: var(--dark-secundary-bg);
    color: white;
}

.input-type-date-button-light {
    background-color: var(--light-secundary-bg);
    color: black;
}

.input-type-date-button-dark:hover {
    background-color: var(--dark-tertiary-bg);
}

.input-type-date-button-light:hover {
    background-color: var(--light-tertiary-bg);
}

.input-type-date-img {
    width: 20px;
    height: 20px;
    padding: 2px 15px;
}

.border-date-alert {
    border: solid 1.5px rgba(255, 0, 0, 0.486);
}

.border-date-alert2 {
    border: solid 3px rgba(255, 0, 0, 0.486);
}

.ncfExpirationDatePlaceholder::placeholder {
    color: rgba(177, 177, 177, 0.87);
    font-weight: 400; 
    opacity: 1; /* Firefox */
}

.input-type-date-button-dark2 {
    background-color: rgb(100, 100, 100);
    color: white;
}

.input-type-date-button-light2 {
    background-color: #F7F7F7;
    color: black;
}

.input-type-date-button-dark2:hover {
    background-color: var(--dark-tertiary-bg);
}

.input-type-date-button-light2:hover {
    background-color: var(--light-tertiary-bg);
}